@import './variables';

body {

    .product-search-container {

        @include desktop {
            .page-title.category-title {
                position: absolute;
                color: $white;
                z-index: 1000;
                text-shadow: 0 0 10px $black;
                top: 0;
                left: 0;
            }
        }

        /// Pagination ///
        .pager {
            .pages {
                li {
                    border-radius: $buttonBorderRadius;

                    &.current {
                        background-color: $orange;
                        color: $white;
                    }

                    a {
                        border-radius: $buttonBorderRadius;

                        &:hover {
                            background-color: $orange;
                            color: $white;
                        }
                    }
                }
            }
        }

        .mobile-bottom-pagination {
            .module-category-misc {
                display: flex;
                justify-content: center;
            }
        }

        /// Category View Mode ///
        .sorter {
            .view-mode {
                span {
                    border-radius: $buttonBorderRadius;

                    &.list.active {
                        background-color: $orange;
                        background-position-y: -237px;
                    }
                }
            }
            
        }

        /// Product Listing ///
        .category-products-grid.hover-effect, .products-list.hover-effect {
            .item {
                margin-bottom: 20px;
                border-bottom: 1px solid $lightGrey;
                padding-left: 0.5%;
                padding-right: 0.5%;
                margin-left: 0.5%;
                margin-right: 0.5%;

                &:hover {
                    box-shadow: 0px 10px 15px $lightGrey;
                    margin-bottom: 21px;
                    border-radius: 10px;
                    border-bottom: none;
                }

                .qty-wrapper {
                    width: 209px;
                    margin-bottom: 5px;

                    input {
                        width: 100% !important;
                        height: 30px;
                    }
                }
            }

            &.products-list.hover-effect {
                .item {
                    .stock-status-column {
                        position: absolute;
                        left: 33.33%;

                        @include desktop {
                            top: 100px;
                        }
                        @include mobile {
                            top: 150px;
                        }

                        .stock-status-box {
                            .stock-status {
                                div {
                                    font-weight: bold;
                                    font-size: $medium;
                                }
                            }
                        }
                    }
                    .qty-wrapper {
                        width: 5em;
                    }
                    button.button.btn-cart.add-cart-button {
                        width: 100%;

                        span {
                            span {
                                padding: $buttonVerticalPadding 0;
                            }
                        }
                    }
                }
            }
        }

        .category-products-grid.hover-effect {
            .item {
                .price {
                    float: none;
                    display: block;
                }
                .qty-wrapper {
                    @include center;
                }
                button.button.btn-cart.add-cart-button {
                    @include center;
                }
            }
        }

        .category-list-tab.item_tab1 {
            display: none;
        }

        @include desktop {
            /// Banners ///
            .no-gutter.category-banner .nested-container {
                width: 104%;
                margin-left: -2%;
            }
        }

        @include mobile {
            /// Product Listing ///
            .category-products-grid.hover-effect {
                .item {
                    .qty-wrapper {
                        width: 130px;
                    }
                }
            }
        }
    }
    
    &[data-product-search-total-items="0"] {
        .page-title.category-title {
            position: relative;
            color: $black;
            z-index: auto;
            text-shadow: none;
        }
    }
}