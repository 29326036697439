/* ============ COLORS ============ */
/* ============ NUMBERS ============ */
/* ============ KEEP GRID WIDTHS ============ */
/* ============ DISPLAY ============ */
/* ============ SCREEN SIZES ============ */
/* ============ FONTS ============ */
/* ============ POSITIONING ============ */
/* ============ BUTTONS ============ */
/* ============ COLORS ============ */
/* ============ NUMBERS ============ */
/* ============ KEEP GRID WIDTHS ============ */
/* ============ DISPLAY ============ */
/* ============ SCREEN SIZES ============ */
/* ============ FONTS ============ */
/* ============ POSITIONING ============ */
/* ============ BUTTONS ============ */
.nav-header-container {
  border-bottom: 1px solid #ddd;
}
.nav-header-container nav.nav-container {
  background-color: rgba(255, 255, 255, 0.9);
}
.nav-header-container nav.nav-container #nav.nav-regular.opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu {
  display: block !important;
}
.nav-header-container nav.nav-container #nav.nav-regular.opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu li.level0:hover > a {
  background-color: #23221d;
  color: #fff;
  /*LM*/
}
.nav-header-container nav.nav-container #nav.nav-regular.opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu li.level0:hover .caret {
  border-top-color: #85724d; /*LM*/
}
.nav-header-container nav.nav-container #nav.nav-regular.opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu li.level0 > a {
  font-weight: 500;
  line-height: 45px;
  font-family: "minion-pro", serif;
  color: #23221d;
}
@media (min-width: 960px) and (max-width: 1279px) {
  .nav-header-container nav.nav-container #nav.nav-regular.opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu li.level0 > a {
    font-size: 14px;
  }
}
@media (min-width: 960px) and (max-width: 1099px) {
  .nav-header-container nav.nav-container #nav.nav-regular.opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu li.level0 > a {
    font-size: 12px;
  }
}
.nav-header-container nav.nav-container #nav.nav-regular.opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu li.level0 > a .caret {
  border-top-color: #85724d;
}
.nav-header-container nav.nav-container #nav.nav-regular.opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu li.level0 .nav-panel--dropdown {
  border-top: 2px solid #85724d;
}
.nav-header-container nav.nav-container #nav.nav-regular.opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu li.level0 .nav-panel--dropdown a {
  font-size: 14px;
}
.nav-header-container nav.nav-container #nav.nav-regular.opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu li.level0 .nav-panel--dropdown a:hover {
  color: #85724d;
}
.nav-header-container nav.nav-container #nav.nav-regular.opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu li.nav-item.nav-item--home.level0 {
  display: none;
}

@media (min-width: 1280px) {
  #root-wrapper header#header .logo-column .logo-wrapper {
    left: -68% !important;
  }
  #root-wrapper header#header .logo-column .logo-wrapper .logo {
    max-width: 145px;
  }
}
@media (min-width: 960px) and (max-width: 1279px) {
  #root-wrapper header#header .logo-column .logo-wrapper .logo {
    max-width: 100px;
  }
}
@media (min-width: 960px) {
  #root-wrapper header#header {
    position: fixed;
    width: 100%;
    z-index: 9998;
  }
  #root-wrapper header#header .top-header-container {
    background-color: #4c4c4c;
    font-size: 14px;
    padding: 5px 0;
  }
  #root-wrapper header#header .top-header-container .nested-container {
    margin-left: auto;
    margin-right: auto;
    float: none !important;
    display: block;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container {
    display: flex;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .grid12-1 {
    width: auto;
    width: initial;
    width: intrinsic;
    width: -moz-max-content;
    width: -webkit-max-content;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .userSwitch {
    margin-left: 5px;
    display: inline-block;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .userSwitch a {
    text-indent: -9999px;
    display: inline-block;
    color: #85724d; /*LM*/
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .userSwitch a:hover {
    color: transparent;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .userSwitch a::after {
    text-indent: 0;
    float: left;
    font-family: "fontAwesome";
    color: #d86827;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .userSwitch a::after {
    content: ""; /*LM*/
    float: right;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container p, #root-wrapper header#header .top-header-container .nested-container .grid-container span {
    margin: 0;
    line-height: 34px;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container a, #root-wrapper header#header .top-header-container .nested-container .grid-container a:hover {
    font-weight: bold;
    color: #d86827;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .module-switch-language .dropdown.lang-switcher:not(.open) div {
    color: #fff;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .module-switch-language .dropdown.lang-switcher:not(.open) .caret {
    border-top-color: #fff;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .module-switch-language .dropdown.lang-switcher .caret {
    display: inline-block;
    margin-left: 3px;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .module-switch-language .dropdown.lang-switcher .dropdown-toggle.cover {
    line-height: 34px;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .module-switch-language .dropdown.lang-switcher .dropdown-menu {
    z-index: 9999;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .links > li {
    line-height: 34px;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .links > li > a {
    text-indent: 0px; /*LM*/
    display: inline-block;
    color: #85724d; /*LM*/
    font-size: 14px; /*LM*/
    padding: 0 5px;
    margin: 0 5px;
    line-height: 34px;
      /*LM*/
      font-weight: 400; font-family: "minion-pro", serif;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .links > li > a:hover {
    color: transparent;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .links > li > a::after {
    text-indent: 0;
    float: left;
    font-family: "fontAwesome";
    color: #fff;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .links > li > a.link-account::after {
    content: ""; /*LM*/
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .links > li > a.link-admin::after {
    content: ""; /*LM*/
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .links > li > a.link-logout::after {
    content: ""; /*LM*/
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .links > li > a.link-login::after {
    content: ""; /*LM*/
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .links > li > a.link-register::after {
    content: ""; /*LM*/
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .links > li > a:hover {
    color: #fff;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container #mini-cart .dropdown-toggle .feature-icon-hover > a {
    line-height: 34px;
    font-size: 14px;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container #mini-cart .dropdown-toggle .feature-icon-hover > a div {
    color: #fff;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container #mini-cart .dropdown-toggle .feature-icon-hover > a span {
    color: #fff;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container #mini-cart .dropdown-toggle .feature-icon-hover > a span.fa {
    color: #fff !important;
    font-size: 18px;
    padding: 0;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container #mini-cart .dropdown-toggle .feature-icon-hover > a .empty, #root-wrapper header#header .top-header-container .nested-container .grid-container #mini-cart .dropdown-toggle .feature-icon-hover > a .hide-below-960 {
    display: none;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container #mini-cart .dropdown-toggle .feature-icon-hover .caret {
    border-top-color: #fff;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container #mini-cart .dropdown-menu {
    color: #23221d;
    z-index: 9999;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container #mini-cart .dropdown-menu button.button span span {
    padding: 5px 13px;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container #mini-cart.open .dropdown-toggle .feature-icon-hover > a div {
    color: #23221d;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container #mini-cart.open .dropdown-toggle .feature-icon-hover > a span {
    color: #23221d;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container #mini-cart.open .dropdown-toggle .feature-icon-hover > a span.fa {
    color: #23221d !important;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container #mini-cart.open .dropdown-toggle .feature-icon-hover > a:hover span.fa::before {
    color: #23221d !important;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container #mini-cart.open .dropdown-toggle .feature-icon-hover .caret {
    border-top-color: #23221d;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .search-column {
    margin: 0 auto;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .search-column .module-search.search-wrapper {
    margin-top: 0;
    width: 100%;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .search-column .module-search.search-wrapper .form-search .input-text {
    padding-right: 34px !important;
    height: 34px;
    line-height: 34px;
    box-shadow: 0 0 2px #fff;
    color: #fff !important;
    font-size: 14px;
    float: none;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .search-column .module-search.search-wrapper .form-search .input-text::placeholder {
    color: #aaa;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .search-column .module-search.search-wrapper .form-search .input-text:hover::placeholder {
    color: #fff;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .search-column .module-search.search-wrapper .form-search .button-search .fa {
    color: #fff;
    padding: 9px;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .dropdown.lang-switcher.item .dropdown-menu li {
    clear: left;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .dropdown.lang-switcher.item .dropdown-menu li span {
    line-height: 21px;
    float: left;
    margin-right: 5px;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .dropdown.lang-switcher.item .dropdown-menu li a {
    float: left;
    color: #23221d;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .dropdown.lang-switcher.item .label.dropdown-icon {
    display: none;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .dropdown.lang-switcher.item .label.dropdown-icon + * {
    text-indent: -9999px;
    width: 19px;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .dropdown.lang-switcher.item .label.dropdown-icon + *::after {
    text-indent: 0;
    float: left;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .dropdown.lang-switcher.item .label.dropdown-icon[style*="us.png"] + *::after {
    content: "EN";
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .dropdown.lang-switcher.item .label.dropdown-icon[style*="fr.png"] + *::after {
    content: "FR";
  }
  #root-wrapper header#header .main-header-container {
    background-color: rgba(255, 255, 255, 0.9);
    color: #23221d;
    line-height: 46px; border-bottom: none !important;
    /*border-bottom: 1px solid #ddd;*/
  }
  #root-wrapper header#header .main-header-container .nested-container {
    margin-left: auto;
    margin-right: auto;
    float: none !important;
    display: block;
  }
  #root-wrapper header#header .main-header-container .nested-container .logo-column {
    position: absolute;
    left: 50%;
    z-index: 10000;
  }
  #root-wrapper header#header .main-header-container .nested-container .logo-column .logo-wrapper {
    position: relative;
    left: -50%;
    top: 4px;
    margin: 0;
  }
  #root-wrapper header#header .main-header-container .nested-container .logo-column .logo-wrapper .logo strong {
    display: none;
  }
}
@media (max-width: 959px) {
  #root-wrapper header#header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9998;
    background-color: rgba(255, 255, 255, 0.9);
    width: 100%;
  }
  #root-wrapper header#header .grid12-12, #root-wrapper header#header .grid-full {
    overflow: hidden;
  }
  #root-wrapper header#header .mobile-header-container {
    float: none;
  }
  #root-wrapper header#header .mobile-header-container .mobile-nav-column {
    margin: 0;
  }
  #root-wrapper header#header .mobile-header-container .mobile-nav-column .header-top-container {
    border-bottom: 2px solid #006253;
  }
  #root-wrapper header#header .mobile-header-container .mobile-nav-column .header-top-container span.fa::before {
    color: #23221d;
  }
  #root-wrapper header#header .mobile-header-container .mobile-nav-column .header-top-container .item-logo {
    position: absolute;
    left: 50%;
    line-height: 0;
  }
  #root-wrapper header#header .mobile-header-container .mobile-nav-column .header-top-container .item-logo .logo-wrapper {
    position: relative;
    left: -50%;
    margin: 0;
  }
  #root-wrapper header#header .mobile-header-container .mobile-nav-column .header-top-container .item-logo .logo-wrapper a.logo {
    max-width: 70px;
  }
  #root-wrapper header#header .mobile-header-container .mobile-nav-column .header-top-container .item-logo .logo-wrapper a.logo img {
    width: 100%;
  }
  #root-wrapper header#header .mobile-header-container .mobile-nav-column .header-top-container .nav-cart .hide-below-960 {
    display: none !important;
  }
  #root-wrapper header#header .mobile-header-container .mobile-nav-column .header-top-container .nav-cart .hide-below-960 .cart-total {
    color: #006253;
    font-weight: bold;
    font-size: 14px;
  }
  #root-wrapper header#header .mobile-header-container .mobile-nav-column .header-top-container .nav-search.fly-out {
    background-color: rgba(255, 255, 255, 0.9);
  }
  #root-wrapper header#header .mobile-header-container .mobile-nav-column .header-top-container .module-switch-language * {
    margin: 0 !important;
  }
  #root-wrapper header#header .mobile-header-container .mobile-nav-column .header-top-container .module-switch-language .dropdown.lang-switcher .caret {
    display: inline-block;
    margin-left: 3px !important;
  }
  #root-wrapper header#header .mobile-header-container .mobile-nav-column .header-top-container .module-switch-language .dropdown.lang-switcher .dropdown-toggle.cover {
    line-height: 41px;
  }
  #root-wrapper header#header .mobile-header-container .mobile-nav-column .header-top-container .module-switch-language .dropdown.lang-switcher .dropdown-menu {
    z-index: 9999;
  }
  #root-wrapper header#header .mobile-header-container .mobile-nav-column .header-top-container .dropdown.lang-switcher.item .dropdown-menu {
    position: fixed;
    top: 41px;
  }
  #root-wrapper header#header .mobile-header-container .mobile-nav-column .header-top-container .dropdown.lang-switcher.item .dropdown-menu li {
    clear: left;
    width: 19px;
  }
  #root-wrapper header#header .mobile-header-container .mobile-nav-column .header-top-container .dropdown.lang-switcher.item .dropdown-menu li span {
    line-height: 21px;
    float: left;
    margin-right: 5px;
  }
  #root-wrapper header#header .mobile-header-container .mobile-nav-column .header-top-container .dropdown.lang-switcher.item .dropdown-menu li a {
    float: left;
    color: #23221d;
  }
  #root-wrapper header#header .mobile-header-container .mobile-nav-column .header-top-container .dropdown.lang-switcher.item .label.dropdown-icon {
    display: none;
  }
  #root-wrapper header#header .mobile-header-container .mobile-nav-column .header-top-container .dropdown.lang-switcher.item .label.dropdown-icon + * {
    text-indent: -9999px;
    color: transparent;
    margin-bottom: 12px !important;
    font-weight: bold;
    width: 16px;
    visibility: hidden;
  }
  #root-wrapper header#header .mobile-header-container .mobile-nav-column .header-top-container .dropdown.lang-switcher.item .label.dropdown-icon + *::after {
    text-indent: 0;
    float: left;
    color: #23221d;
    visibility: visible;
  }
  #root-wrapper header#header .mobile-header-container .mobile-nav-column .header-top-container .dropdown.lang-switcher.item .label.dropdown-icon[style*="us.png"] + *::after {
    content: "EN";
  }
  #root-wrapper header#header .mobile-header-container .mobile-nav-column .header-top-container .dropdown.lang-switcher.item .label.dropdown-icon[style*="fr.png"] + *::after {
    content: "FR";
  }
  #root-wrapper header#header .mobile-header-container .mobile-nav-column .header-top-container .form-search .button, #root-wrapper header#header .mobile-header-container .mobile-nav-column .header-top-container span.fa, #root-wrapper header#header .mobile-header-container .mobile-nav-column .header-top-container a:hover span.fa::after {
    color: #23221d !important;
  }
}

/* ==== FRENCH LANGUAGE HEADER === */
@media (min-width: 1280px) {
  html[lang=fr] #root-wrapper header#header .logo-column .logo-wrapper {
    left: -50% !important;
  }
}
@media (min-width: 960px) and (max-width: 1279px) {
  html[lang=fr] #root-wrapper header#header .logo-column .logo-wrapper {
    left: -10% !important;
  }
}
@media only screen and (min-width: 1280px) and (max-width: 1399px) {
  html[lang=fr] #root-wrapper header#header .logo-column .logo-wrapper {
    left: -10% !important;
  }
}

/*# sourceMappingURL=header.css.map */
