/* ============ COLORS ============ */
/* ============ NUMBERS ============ */
/* ============ KEEP GRID WIDTHS ============ */
/* ============ DISPLAY ============ */
/* ============ SCREEN SIZES ============ */
/* ============ FONTS ============ */
/* ============ POSITIONING ============ */
/* ============ BUTTONS ============ */
.nav-header-container {
  border-bottom: 1px solid #ddd; background-color: #000;
}
.nav-header-container nav.nav-container {
  background-color: #000; /*rgba(255, 255, 255, 0.9);*/
}
.nav-header-container nav.nav-container #nav.nav-regular.opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu {
  display: block !important;
}
.nav-header-container nav.nav-container #nav.nav-regular.opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu li.level0:hover > a {
  background-color: #23221d;
  color: #fff;
  /*LM*/
}
.nav-header-container nav.nav-container #nav.nav-regular.opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu li.level0:hover .caret {
  border-top-color: #85724d; /*LM*/
}
.nav-header-container nav.nav-container #nav.nav-regular.opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu li.level0 > a {
  font-weight: 500;
  line-height: 45px;
  font-family: "minion-pro", serif;
  color: #23221d;
}
@media (min-width: 960px) and (max-width: 1279px) {
  .nav-header-container nav.nav-container #nav.nav-regular.opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu li.level0 > a {
    font-size: 14px;
  }
}
@media (min-width: 960px) and (max-width: 1099px) {
  .nav-header-container nav.nav-container #nav.nav-regular.opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu li.level0 > a {
    font-size: 12px;
  }
}
.nav-header-container nav.nav-container #nav.nav-regular.opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu li.level0 > a .caret {
  border-top-color: #85724d;
}
.nav-header-container nav.nav-container #nav.nav-regular.opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu li.level0 .nav-panel--dropdown {
  border-top: 2px solid #85724d;
}
.nav-header-container nav.nav-container #nav.nav-regular.opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu li.level0 .nav-panel--dropdown a {
  font-size: 14px;
}
.nav-header-container nav.nav-container #nav.nav-regular.opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu li.level0 .nav-panel--dropdown a:hover {
  color: #85724d;
}
.nav-header-container nav.nav-container #nav.nav-regular.opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu li.nav-item.nav-item--home.level0 {
  display: none;
}

/*# sourceMappingURL=nav.css.map */
