@import './variables';
@import './nav';

#root-wrapper {
    header#header {
        @include largeDesktop {
            //logo
            .logo-column {
                .logo-wrapper {
                    left: -68% !important;

                    .logo {
                        max-width: 145px;
                    }
                }
            }
        }
        @include smallDesktop {
            //logo
            .logo-column {
                .logo-wrapper {
                    .logo {
                        max-width: 100px;
                    }
                }
            }
        }
        @include desktop {
            position: fixed;
            width: 100%;
            z-index: 9998;

            .top-header-container {
                background-color: $darkGrey;
                font-size: $medium;
                padding: 5px 0;

                .nested-container {
                    @include center;

                    .grid-container {
                        display: flex;

                        .grid12-1 {
                            width:auto;
                            width:initial;
                            width:intrinsic;
                            width:-moz-max-content;
                            width:-webkit-max-content;
                        }
                        
                        .userSwitch {
                            margin-left: 5px;
                            display: inline-block;
    
                            a {
                                @include replaceTextWithFontAwesome($orange);
    
                                &::after {
                                    content: "";/*LM*/
                                    float: right;
                                }
                            }
                        }
    
                        p, span {
                            margin: 0;
                            line-height: 34px;
                        }
    
                        a, a:hover {
                            font-weight: bold;
                            color: $orange;
                        }
    
                        //switch language module
                        .module-switch-language {
                            .dropdown.lang-switcher {
                                &:not(.open) {
                                    div {
                                        color: $white;
                                    }
                                    
                                    .caret {
                                        border-top-color: $white;
                                    }
                                }

                                .caret {
                                    display: inline-block;
                                    margin-left: 3px;
                                }
    
                                .dropdown-toggle.cover {
                                    line-height: $searchBarHeight;
                                }

                                .dropdown-menu {
                                    z-index: 9999;
                                }
                            }
                        }
    
                        //user links
                        .links {
                            & > li {
                                line-height: $searchBarHeight;
    
                                & > a {
                                    @include replaceTextWithFontAwesome($white);
                                    font-size: $mediumLarge;
                                    padding: 0 5px;
                                    margin: 0 5px;
                                    line-height: $searchBarHeight;
    
                                    &.link-account::after {
                                        content: ''; /*LM*/
                                    }
                                    &.link-admin::after {
                                        content: '';/*LM*/
                                    }
                                    &.link-logout::after {
                                        content: '';/*LM*/
                                    }
                                    &.link-login::after {
                                        content: '';/*LM*/
                                    }
                                    &.link-register::after {
                                        content: '';/*LM*/
                                    }
    
                                    &:hover {
                                        color: $white;
                                    }
                                }
                            }
                        }
    
                        //cart
                        #mini-cart {
                            .dropdown-toggle {
                                .feature-icon-hover {
                                    & > a {
                                        line-height: $searchBarHeight;
                                        font-size: $medium;
    
                                        div {
                                            color: $white;
                                        }
                                        span {
                                            color: $white;
                                            &.fa {
                                                color: $white !important;
                                                font-size: $large;
                                                padding: 0;
                                            }
                                        }
    
                                        .empty, .hide-below-960 {
                                            display: none;
                                        }
                                    }
    
                                    .caret {
                                        border-top-color: $white;
                                    }
                                }
                            }
    
                            .dropdown-menu {
                                color: $black;
                                z-index: 9999;
    
                                button.button {
                                    span {
                                        span {
                                            padding: 5px $buttonVerticalPadding;
                                        }
                                    }
                                }
                            }
    
                            &.open {
                                .dropdown-toggle {
                                    .feature-icon-hover {
                                        & > a {
                                            div {
                                                color: $black;
                                            }
                                            span {
                                                color: $black;
                                                &.fa {
                                                    color: $black !important;
                                                }
                                            }
                                            &:hover {
                                                span.fa {
                                                    &::before {
                                                        color: $black !important;
                                                    }
                                                }
                                            }
                                        }
    
                                        .caret {
                                            border-top-color: $black;
                                        }
                                    }
                                }
                            }
                        }
    
                        //search bar
                        .search-column {
                            margin: 0 auto;

                            .module-search.search-wrapper {
                                margin-top: 0;
                                width: 100%;
        
                                .form-search {
                                    .input-text {
                                        padding-right: $searchBarHeight !important;
                                        height: $searchBarHeight;
                                        line-height: $searchBarHeight;
                                        box-shadow: 0 0 $buttonBorderWidth $white;
                                        color: $white !important;
                                        font-size: $medium;
                                        float: none;
        
                                        &::placeholder {
                                            color: #aaa;
                                        }
        
                                        &:hover {
                                            &::placeholder {
                                                color: $white;
                                            }
                                        }
                                    }
                                    .button-search {
                                        .fa {
                                            color: $white;
                                            padding: 9px;
                                        }
                                    }
                                }
                            }
                        }

                        //language switcher
                        .dropdown.lang-switcher.item {
                            .dropdown-menu {
                                li {
                                    clear: left;

                                    span {
                                        line-height: 21px;
                                        float: left;
                                        margin-right: 5px;
                                    }

                                    a {
                                        float: left;
                                        color: $black;
                                    }
                                }
                            }

                            .label.dropdown-icon {
                                display: none;

                                & + * {
                                    text-indent: -9999px;
                                    width: 19px;

                                    &::after {
                                        text-indent: 0;
                                        float: left;
                                    }
                                }

                                &[style*="us.png"] + *::after {
                                    content: "EN";
                                }

                                &[style*="fr.png"] + *::after {
                                    content: "FR";
                                }
                            }
                        }
                    }
                }
            }

            // main header container
            .main-header-container {
                background-color: $translucentWhite;
                color: $black;
                line-height: $mainHeaderContainerHeight;
                border-bottom: 1px solid #ddd;

                .nested-container {
                    @include center;
                    
                    // logo
                    .logo-column {
                        @include horizontalAbsoluteCenter;
                        z-index: 10000;

                        .logo-wrapper {
                            @include horizontalAbsoluteCenterChild;
                            top: 4px;
                            margin: 0;

                            .logo {
                                strong {
                                    @include elementDisplay($hide);
                                }
                            }
                        }
                    }
                }
            }
        }
        @include mobile {
            //floating header
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            z-index: 9998;
            background-color: $translucentWhite;
            width: 100%;

            .grid12-12, .grid-full {
                overflow: hidden;
            }

            .mobile-header-container {
                float: none;

                .mobile-nav-column {
                    margin: 0;
                    
                    .header-top-container {
                        border-bottom: $buttonBorderWidth solid $green;

                        //nav 
                        //hamburrger menu icon
                        span.fa {
                            &::before {
                                color: $black;
                            }
                        }
                    
                        //logo
                        .item-logo {
                            @include horizontalAbsoluteCenter;
                            line-height: 0;

                            .logo-wrapper {
                                @include horizontalAbsoluteCenterChild;
                                margin: 0;

                                a.logo {
                                    max-width: 70px;

                                    img {
                                        width: 100%;
                                    }
                                }
                            }
                        }

                        //cart
                        .nav-cart {
                            .hide-below-960 {
                                display: none !important;

                                .cart-total {
                                    color: $green;
                                    font-weight: bold;
                                    font-size: $medium;
                                }
                            }
                        }

                        //search
                        .nav-search.fly-out {
                            background-color: $translucentWhite;
                        }

                        //switch language module
                        .module-switch-language {

                            * {
                                margin: 0 !important;
                            }

                            .dropdown.lang-switcher {

                                .caret {
                                    display: inline-block;
                                    margin-left: 3px !important;
                                }
    
                                .dropdown-toggle.cover {
                                    line-height: 41px;
                                }

                                .dropdown-menu {
                                    z-index: 9999;
                                }
                            }
                        }

                        //language switcher
                        .dropdown.lang-switcher.item {
                            .dropdown-menu {
                                position: fixed;
                                top: 41px;

                                li {
                                    clear: left;
                                    width: 19px;

                                    span {
                                        line-height: 21px;
                                        float: left;
                                        margin-right: 5px;
                                    }

                                    a {
                                        float: left;
                                        color: $black;
                                    }
                                }
                            }

                            .label.dropdown-icon {
                                display: none;

                                & + * {
                                    text-indent: -9999px;
                                    color: transparent;
                                    margin-bottom: $small !important;
                                    font-weight: bold;
                                    width: $mediumLarge;
                                    visibility: hidden;

                                    &::after {
                                        text-indent: 0;
                                        float: left;
                                        color: $black;
                                        visibility: visible;
                                    }
                                }

                                &[style*="us.png"] + *::after {
                                    content: "EN";
                                }

                                &[style*="fr.png"] + *::after {
                                    content: "FR";
                                }
                            }
                        }

                        .form-search .button, span.fa, a:hover span.fa::after {
                            color: $black !important;
                        }
                    }
                }
            }
        }
    }
}

/* ==== FRENCH LANGUAGE HEADER === */
html[lang="fr"] {
    #root-wrapper {
        header#header {
            @include largeDesktop {
                //logo
                .logo-column {
                    .logo-wrapper {
                        left: -50% !important;
                    }
                }
            }

            @include smallDesktop {
                //logo
                .logo-column {
                    .logo-wrapper {
                        left: -10% !important;
                    }
                }
            }

            @media only screen and (min-width: #{$minLargeDesktopScreenWidth}) and (max-width: 1399px) {
                //logo
                .logo-column {
                    .logo-wrapper {
                        left: -10% !important;
                    }
                }
            }
        }   
    }
}