/* ============ COLORS ============ */
/* ============ NUMBERS ============ */
/* ============ KEEP GRID WIDTHS ============ */
/* ============ DISPLAY ============ */
/* ============ SCREEN SIZES ============ */
/* ============ FONTS ============ */
/* ============ POSITIONING ============ */
/* ============ BUTTONS ============ */
footer#footer {
  background-color: #23221d;
}
footer#footer .footer-container2 > div[class*=footer-] {
  display: block;
  clear: both;
  float: none;
}
footer#footer .footer-container2 .footer-top-container {
  /*LM commented it - background-image: url(https://cdn.mysagestore.com/7fe73e2125e5ed677797d269f269f645/contents/ckfinder/images/footer/footerv2a.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;*/
  /*LM added it*/
  background: url(https://cdn.mysagestore.com/4eaf1606a2fa5a515ef09a6c0cbe1441/contents/ckfinder/images/bg-swirl-2.png) 0 0 #601424;
}
footer#footer .footer-container2 .footer-top-container .footer-top.footer.container {
  margin: 0;
  width: 100%;
}
footer#footer .footer-container2 .footer-top-container .footer-top.footer.container .footer-logo-column img {
  display: block;
  margin: auto;
}
@media (min-width: 960px) {
  footer#footer .footer-container2 .footer-top-container .footer-top.footer.container .footer-logo-column img {
    width: 50%;
  }
}
@media (max-width: 959px) {
  footer#footer .footer-container2 .footer-top-container .footer-top.footer.container .footer-logo-column img {
    width: 70%;
  }
}
footer#footer .footer-container2 .footer-top-container h6.block-title.heading {
  color: #fff;
  font-size: 12px;
  font-weight: bold;
}
footer#footer .footer-container2 .footer-top-container ul li a {
  color: #FF0000; /*LM$white;*/
  font-size: 12px;
}
@media (min-width: 960px) and (max-width: 1279px) {
  footer#footer .footer-container2 .footer-top-container h6.block-title.heading {
    font-size: 10px;
  }
}
@media (min-width: 768px) and (max-width: 959px) {
  footer#footer .footer-container2 .footer-top-container h6.block-title.heading {
    font-size: 8px;
  }
  footer#footer .footer-container2 .footer-top-container ul {
    font-size: 10px;
  }
}
footer#footer .footer-container2 .footer-primary-container h6.block-title.heading {
  color: #fff;
  font-weight: bold;
}
footer#footer .footer-container2 .footer-primary-container .social-links.icon-wrapper-square {
  text-align: right;
}
footer#footer .footer-container2 .footer-primary-container .item.item-right.newsletter-wrapper {
  float: left;
  margin: 0;
}
footer#footer .footer-container2 .footer-primary-container .item.item-right.newsletter-wrapper #subscribe-form .button.btn-inline.newsletter-button {
  float: left;
}
footer#footer .footer-container2 .footer-primary-container .item.item-right.newsletter-wrapper #subscribe-form .button.btn-inline.newsletter-button span span {
  padding: 13px;
}
footer#footer .footer-container2 .footer-primary-bottom.container {
  background-color: #4c4c4c;
}
footer#footer .footer-container2 .footer-primary-bottom.container .footer-primary-bottom-spacing {
  padding: 10px 0;
}
footer#footer .footer-container2 .footer-primary-bottom.container .footer-primary-bottom-spacing ul li a {
  color: #d86827;
  font-weight: bold;
}
footer#footer .footer-container2 .footer-bottom-container {
  display: none !important;
}
footer#footer .footer-container2 .footer-bottom-container .section.clearer {
  display: none;
}
footer#footer .footer-container2 .footer {
  /* Back link */
  /* Slider arrows */
  /* Slider pagination */
  /* deprecated */
  /* Accordion and collapsible: opener */
}
footer#footer .footer-container2 .footer .buttons-set .back-link small {
  border: 2px solid #006253;
  border-radius: 3px;
  background-color: #85724d;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  height: auto;
}
footer#footer .footer-container2 .footer .buttons-set .back-link:hover small {
  background-color: #fff;
  color: #006253;
}
footer#footer .footer-container2 .footer .slider-arrows1 .owl-controls, footer#footer .footer-container2 .footer .slider-arrows2 .owl-controls, footer#footer .footer-container2 .footer .slider-arrows3 .owl-controls, footer#footer .footer-container2 .footer .slider-pagination1 .owl-controls, footer#footer .footer-container2 .footer .slider-pagination2 .owl-controls {
  /* Slider pagination */
  /* Slider arrows */
}
footer#footer .footer-container2 .footer .slider-arrows1 .owl-controls .owl-page span, footer#footer .footer-container2 .footer .slider-arrows2 .owl-controls .owl-page span, footer#footer .footer-container2 .footer .slider-arrows3 .owl-controls .owl-page span, footer#footer .footer-container2 .footer .slider-pagination1 .owl-controls .owl-page span, footer#footer .footer-container2 .footer .slider-pagination2 .owl-controls .owl-page span {
  border: 2px solid #006253;
  border-radius: 3px;
  background-color: #85724d;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  height: auto;
}
footer#footer .footer-container2 .footer .slider-arrows1 .owl-controls.clickable .owl-page:hover span, footer#footer .footer-container2 .footer .slider-arrows2 .owl-controls.clickable .owl-page:hover span, footer#footer .footer-container2 .footer .slider-arrows3 .owl-controls.clickable .owl-page:hover span, footer#footer .footer-container2 .footer .slider-pagination1 .owl-controls.clickable .owl-page:hover span, footer#footer .footer-container2 .footer .slider-pagination2 .owl-controls.clickable .owl-page:hover span {
  background-color: #fff;
  color: #006253;
}
footer#footer .footer-container2 .footer .slider-arrows1 .owl-controls.clickable .owl-buttons div:hover, footer#footer .footer-container2 .footer .slider-arrows2 .owl-controls.clickable .owl-buttons div:hover, footer#footer .footer-container2 .footer .slider-arrows3 .owl-controls.clickable .owl-buttons div:hover, footer#footer .footer-container2 .footer .slider-pagination1 .owl-controls.clickable .owl-buttons div:hover, footer#footer .footer-container2 .footer .slider-pagination2 .owl-controls.clickable .owl-buttons div:hover {
  background-color: #fff;
  color: #006253;
}
footer#footer .footer-container2 .footer .slider-arrows1 .owl-controls.clickable .owl-buttons div.disabled:hover, footer#footer .footer-container2 .footer .slider-arrows2 .owl-controls.clickable .owl-buttons div.disabled:hover, footer#footer .footer-container2 .footer .slider-arrows3 .owl-controls.clickable .owl-buttons div.disabled:hover, footer#footer .footer-container2 .footer .slider-pagination1 .owl-controls.clickable .owl-buttons div.disabled:hover, footer#footer .footer-container2 .footer .slider-pagination2 .owl-controls.clickable .owl-buttons div.disabled:hover {
  border: 2px solid #006253;
  border-radius: 3px;
  background-color: #85724d;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  height: auto;
}
footer#footer .footer-container2 .footer .slider-arrows1 .owl-controls .owl-buttons div, footer#footer .footer-container2 .footer .slider-arrows2 .owl-controls .owl-buttons div, footer#footer .footer-container2 .footer .slider-arrows3 .owl-controls .owl-buttons div, footer#footer .footer-container2 .footer .slider-pagination1 .owl-controls .owl-buttons div, footer#footer .footer-container2 .footer .slider-pagination2 .owl-controls .owl-buttons div {
  border: 2px solid #006253;
  border-radius: 3px;
  background-color: #85724d;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  height: auto;
}
footer#footer .footer-container2 .footer .gen-slider-arrows1 .direction-nav a, footer#footer .footer-container2 .footer .gen-slider-arrows2 .direction-nav a, footer#footer .footer-container2 .footer .gen-slider-arrows3 .direction-nav a {
  border: 2px solid #006253;
  border-radius: 3px;
  background-color: #85724d;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  height: auto;
}
footer#footer .footer-container2 .footer .gen-slider-arrows1 .direction-nav a.disabled:hover, footer#footer .footer-container2 .footer .gen-slider-arrows2 .direction-nav a.disabled:hover, footer#footer .footer-container2 .footer .gen-slider-arrows3 .direction-nav a.disabled:hover {
  border: 2px solid #006253;
  border-radius: 3px;
  background-color: #85724d;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  height: auto;
}
footer#footer .footer-container2 .footer .gen-slider-arrows1 .direction-nav a:hover, footer#footer .footer-container2 .footer .gen-slider-arrows2 .direction-nav a:hover, footer#footer .footer-container2 .footer .gen-slider-arrows3 .direction-nav a:hover {
  background-color: #fff;
  color: #006253;
}
footer#footer .footer-container2 .footer .accordion-style1 .opener, footer#footer .footer-container2 .footer .collapsible .opener {
  border: 2px solid #006253;
  border-radius: 3px;
  background-color: #85724d;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  height: auto;
}
footer#footer .footer-container2 .footer .accordion-style1 .opener:hover, footer#footer .footer-container2 .footer .collapsible .opener:hover {
  background-color: #fff;
  color: #006253;
}
@media only screen and (min-width: 768px) {
  footer#footer .footer-container2 .footer .footer-social-column {
    max-width: 19%;
  }
}
@media (max-width: 767px) {
  footer#footer .footer-container2 .footer .footer-social-column .item-right {
    float: none;
    margin: 0;
  }
}
footer#footer .footer-container2 .footer .footer-social-column .item-right .social-links.icon-wrapper-circle {
  text-align: right;
  display: flex;
  justify-content: flex-start;
  flex-direction: row-reverse;
  flex-wrap: wrap;
}
@media (max-width: 767px) {
  footer#footer .footer-container2 .footer .footer-social-column .item-right .social-links.icon-wrapper-circle {
    justify-content: flex-end;
  }
}
footer#footer .footer-container2 .footer .footer-social-column .item-right .social-links.icon-wrapper-circle a {
  background-color: #fff;
  border-radius: 50%;
  text-align: center;
  margin-left: 5px;
  width: 50px;
  height: 50px;
}
footer#footer .footer-container2 .footer .footer-social-column .item-right .social-links.icon-wrapper-circle a i.fa {
  line-height: 50px;
  font-size: 30px;
}
@media (max-width: 959px) {
  footer#footer .footer-container2 .footer-primary-bottom.container {
    padding: 0;
  }
  footer#footer .footer-container2 .collapsible .opener {
    margin-right: 15px;
  }
}
/*LM added the changes for footer: start*/
.footer-top .section {
     width:100% 
}
footer a, .copyright {
     font-family:"minion-pro", serif;
     color:#85724d !important;
     display:inline;
     font-size:14px !important;
}
footer .copyright-column {
     margin-top:20px;
}
.copyright {
     bottom:10px;
     text-transform:uppercase;
}
footer .logo img {
     width:150px;
}
footer .module-logo {
     text-align:right;
}
.facebook-icon img {
     width:30%;
     height:30%;
     margin-left:25px;
}
.facebook-icon p a {
     font-size:1.5em;
     letter-spacing:1.5px;
}
.footer-top .section > div.social-icon-column {
     width:13%;
     margin-top:30px;
     margin-right:10%;
}
.footer-top .section > div.footer-links-column {
     margin-top:20px;
}
.footer-top .section > div.logo-column {
     width:200px;
     margin-top:10px;
}
@media only screen and (min-width:960px) and (max-width:1350px){
     .footer-top .section > div.logo-column {
         width:140px;
    }
     footer a, .copyright {
         font-size:12px;
    }
}
/*mobile devices*/
@media only screen and (max-width:767px) {
     .facebook-icon img {
         width:7%;
    }
     footer a, .copyright {
         font-size:14px !important;
    }
     .footer-top .section {
         text-align:center;
    }
     .facebook-icon img {
         margin-left:0;
    }
     footer .module-logo {
         width:70px;
    }
     .footer-top .section > div.social-icon-column {
         margin-top:20px;
    }
     .footer-top .block-title.heading, .footer-top .mobile-collapsible li {
         text-align:left;
    }
}
/*tablet devices*/
@media only screen and (min-width:768px) and (max-width:959px) {
     .footer-top .section > div.social-icon-column {
         width:13%;
         margin-top:20px;
         margin-right:0;
    }
     .footer-top .section > div.footer-links-column {
         width:55%;
         margin-left:50px;
         margin-top:20px;
    }
     .footer-top .section > div.logo-column {
         width:140px;
    }
     .footer-top .mobile-collapsible li a {
         font-size:12px !important;
    }
     .footer-top-container h6.block-title.heading {
         font-size:14px !important;
    }
}
/*:end*/
/*# sourceMappingURL=footer.css.map */
