/* ============ COLORS ============ */
/* ============ NUMBERS ============ */
/* ============ KEEP GRID WIDTHS ============ */
/* ============ DISPLAY ============ */
/* ============ SCREEN SIZES ============ */
/* ============ FONTS ============ */
/* ============ POSITIONING ============ */
/* ============ BUTTONS ============ */
@media (min-width: 960px) {
  body .product-search-container .page-title.category-title {
    /*position: absolute;
    color: #fff;
    z-index: 1000;
    text-shadow: 0 0 10px #23221d;
    top: 0;
    left: 0;*/
  }
}
body .product-search-container .pager .pages li {
  border-radius: 3px;
}
body .product-search-container .pager .pages li.current {
  background-color: #d86827;
  color: #fff;
}
body .product-search-container .pager .pages li a {
  border-radius: 3px;
}
body .product-search-container .pager .pages li a:hover {
  background-color: #d86827;
  color: #fff;
}
body .product-search-container .mobile-bottom-pagination .module-category-misc {
  display: flex;
  justify-content: center;
}
body .product-search-container .sorter .view-mode span {
  border-radius: 3px;
}
body .product-search-container .sorter .view-mode span.list.active {
  background-color: #d86827;
  background-position-y: -237px;
}
body .product-search-container .category-products-grid.hover-effect .item, body .product-search-container .products-list.hover-effect .item {
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
  padding-left: 0.5%;
  padding-right: 0.5%;
  margin-left: 0.5%;
  margin-right: 0.5%;
}
body .product-search-container .category-products-grid.hover-effect .item:hover, body .product-search-container .products-list.hover-effect .item:hover {
  box-shadow: 0px 10px 15px #ddd;
  margin-bottom: 21px;
  border-radius: 10px;
  border-bottom: none;
}
body .product-search-container .category-products-grid.hover-effect .item .qty-wrapper, body .product-search-container .products-list.hover-effect .item .qty-wrapper {
  width: 209px;
  margin-bottom: 5px;
}
body .product-search-container .category-products-grid.hover-effect .item .qty-wrapper input, body .product-search-container .products-list.hover-effect .item .qty-wrapper input, body .product-search-container .products-line.hover-effect .item .qty-wrapper input, .products-line .module-category-product-listing .qty-wrapper input.qty {
  width: 5em !important;
  height: 30px;
}
body .product-search-container .category-products-grid.hover-effect.products-list.hover-effect .item .stock-status-column, body .product-search-container .products-list.hover-effect.products-list.hover-effect .item .stock-status-column {
  position: absolute;
  left: 33.33%;
}
@media (min-width: 960px) {
  body .product-search-container .category-products-grid.hover-effect.products-list.hover-effect .item .stock-status-column, body .product-search-container .products-list.hover-effect.products-list.hover-effect .item .stock-status-column {
    top: 100px;
  }
}
@media (max-width: 959px) {
  body .product-search-container .category-products-grid.hover-effect.products-list.hover-effect .item .stock-status-column, body .product-search-container .products-list.hover-effect.products-list.hover-effect .item .stock-status-column {
    top: 150px;
  }
}
body .product-search-container .category-products-grid.hover-effect.products-list.hover-effect .item .stock-status-column .stock-status-box .stock-status div, body .product-search-container .products-list.hover-effect.products-list.hover-effect .item .stock-status-column .stock-status-box .stock-status div {
  font-weight: bold;
  font-size: 14px;
}
body .product-search-container .category-products-grid.hover-effect.products-list.hover-effect .item .qty-wrapper, body .product-search-container .products-list.hover-effect.products-list.hover-effect .item .qty-wrapper {
  width: 5em;
}
body .product-search-container .category-products-grid.hover-effect.products-list.hover-effect .item button.button.btn-cart.add-cart-button, body .product-search-container .products-list.hover-effect.products-list.hover-effect .item button.button.btn-cart.add-cart-button {
  width: 100%;
}
body .product-search-container .category-products-grid.hover-effect.products-list.hover-effect .item button.button.btn-cart.add-cart-button span span, body .product-search-container .products-list.hover-effect.products-list.hover-effect .item button.button.btn-cart.add-cart-button span span {
  padding: 13px 0;
}
body .product-search-container .category-products-grid.hover-effect .item .price {
  float: none;
  display: block;
}
body .product-search-container .category-products-grid.hover-effect .item .qty-wrapper {
  margin-left: auto;
  margin-right: auto;
  float: none !important;
  display: block;
}
body .product-search-container .category-products-grid.hover-effect .item button.button.btn-cart.add-cart-button {
  margin-left: auto;
  margin-right: auto;
  float: none !important;
  display: block;
}
body .product-search-container .category-list-tab.item_tab1 {
  display: none;
}
@media (min-width: 960px) {
  body .product-search-container .no-gutter.category-banner .nested-container {
    width: 104%;
    margin-left: -2%;
  }
}
@media (max-width: 959px) {
  body .product-search-container .category-products-grid.hover-effect .item .qty-wrapper {
    width: 130px;
  }
}
body[data-product-search-total-items="0"] .page-title.category-title {
  position: relative;
  color: #23221d;
  z-index: auto;
  text-shadow: none;
}

/*# sourceMappingURL=category.css.map */
