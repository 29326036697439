/* ============ COLORS ============ */
$white: #fff;
$translucentWhite: rgba(255, 255, 255, 0.9);
$translucentBlack: rgba(0,0,0,0.3);
$green: #006253;
$pickle: #446e52;
$black: #23221d;
$darkGrey: #4c4c4c;
$lightGrey: #ddd;
$orange: #d86827;
$red: #84251a;
$brown: #96774a;
$golden: #85724d;

/// Background and Text Color ///
@mixin backgroundTextColor($backgroundColor, $textColor) {
    background-color: $backgroundColor;
    color: $textColor;
}

/* ============ NUMBERS ============ */
$halfPercentage: 50%;

/* ============ KEEP GRID WIDTHS ============ */

@mixin keepGridWidths {
    .keep-width.grid12-1 {
        width: 6.33% !important;
    }
    .keep-width.grid12-2 {
        width: 14.66% !important;
    }
    .keep-width.grid12-3 {
        width: 23% !important;
    }
    .keep-width.grid12-4 {
        width: 31.33% !important;
    }
    .keep-width.grid12-5 {
        width: 39.67% !important;
    }
    .keep-width.grid12-6 {
        width: 48% !important;
    }
    .keep-width.grid12-7 {
        width: 56.33% !important;
    }
    .keep-width.grid12-8 {
        width: 64.67% !important;
    }
    .keep-width.grid12-9 {
        width: 73% !important;
    }
    .keep-width.grid12-10 {
        width: 81.33% !important;
    }
    .keep-width.grid12-11 {
        width: 89.67% !important;
    }
}


/* ============ DISPLAY ============ */
// None
$hide: none;

@mixin elementDisplay($displayVal) {
    display: $displayVal;
}
/* ============ SCREEN SIZES ============ */
/// Desktop ///
// min larger desktop screen width
$minLargeDesktopScreenWidth: 1280px;
// max small desktop screen width
$maxSmallDesktopScreenWidth: 1279px;
// max very small desktop screen width
$maxVerySmallDesktopScreenWidth: 1099px;
// min desktop screen width
$minDesktopScreenWidth: 960px;

/// Mobile ///
// max mobile screen width
$maxMobileScreenWidth: 959px;
// min tablet screen width
$minTabletScreenWidth: 768px;
// max phablet screen width
$maxPhabletScreenWidth: 767px;
// min phablet screen width
$minPhabletScreenWidth: 480px;
// max phone screen width
$maxPhoneScreenWidth: 479px;

@mixin largeDesktop {
    @media (min-width: #{$minLargeDesktopScreenWidth}) {
        @content;
    }
}
@mixin smallDesktop {
    @media (min-width: #{$minDesktopScreenWidth}) and (max-width: #{$maxSmallDesktopScreenWidth}) {
        @content;
    }
}
@mixin verySmallDesktop {
    @media (min-width: #{$minDesktopScreenWidth}) and (max-width: #{$maxVerySmallDesktopScreenWidth}) {
        @content;
    }
}
@mixin desktop {
    @media (min-width: #{$minDesktopScreenWidth}) {
        @content;
    }
}
@mixin smallDesktopAndLargeMobile {
    @media (min-width: #{$minTabletScreenWidth}) and (max-width: #{$maxSmallDesktopScreenWidth}) {
        @content;
    }
}
@mixin mobile {
    @media (max-width: #{$maxMobileScreenWidth}) {
        @content;
    }
}
@mixin tablet {
    @media (min-width: #{$minTabletScreenWidth}) and (max-width: #{$maxMobileScreenWidth}) {
        @content;
    }
}
@mixin phablet {
    @media (min-width: #{$minPhabletScreenWidth}) and (max-width: #{$maxPhabletScreenWidth}) {
        @content;
    }
}
@mixin allPhones {
    @media (max-width: #{$maxPhabletScreenWidth}) {
        @content;
    }
}
@mixin phone {
    @media (max-width: #{$maxPhoneScreenWidth}) {
        @content;
    }
}

/* ============ FONTS ============ */

/// FONT FAMILY ///
$oswaldFont: 'Oswald',Helvetica,Arial,sans-serif;
$latoFont: 'Lato',Helvetica,Arial,sans-serif;
$minionFont: "minion-pro", serif;
$fontAwesome: 'fontAwesome';

//font mixin
@mixin fontFamily($family...) {
    font-family: $family;
}

//oswald font function
@mixin oswaldFont {
    @include fontFamily($minionFont);
}

//lato font function
@mixin latoFont {
    @include fontFamily($minionFont);
}

/// Font Awesome ///
//font awesome font function
@mixin fontAwesome {
    @include fontFamily($fontAwesome);
}

//replace text with font awesome 
@mixin replaceTextWithFontAwesome($color) {
    text-indent: -9999px;
    display: inline-block;
    color: #85724d; /*LM*/

    &:hover {
        color: transparent;
    }

    &::after {
        text-indent: 0;
        float: left;
        @include fontAwesome;
        color: $color;
    }
}

/// Sizes ///
//font sizes
$xXSmall: 8px;
$xSmall: 10px;
$small: 12px;
$medium: 14px;
$mediumLarge: 16px;
$large: 18px;
$xLarge: 20px;

//line heights
$mainHeaderContainerHeight: 46px;
$searchBarHeight: 34px;

/* ============ POSITIONING ============ */
/// Relative ///
@mixin relativePosition {
    position: relative;
}
/// Absolute ///
@mixin absolutePosition {
    position: absolute;
}
//hoizontal center
@mixin horizontalAbsoluteCenter {
    @include absolutePosition();
    left: $halfPercentage;
}
@mixin horizontalAbsoluteCenterChild {
    @include relativePosition();
    left: -#{$halfPercentage};
}

@mixin center {
    margin-left: auto;
    margin-right: auto;
    float: none !important;
    display: block;
}

/* ============ BUTTONS ============ */
$buttonBorderRadius: 3px;
$buttonBorderWidth: 2px;
$buttonVerticalPadding: 13px;
$buttonSidePadding: 54px;

@mixin buttonStyling {
    border: $buttonBorderWidth solid $green;
    border-radius: $buttonBorderRadius;
    background-color: $golden;
    color: $white;
    font-size: $medium;
    font-weight: bold;
    line-height: normal;
    height: auto;
}

@mixin buttonHoverStyling {
    background-color: $white;
    color: $green;
}

@mixin controlButtonStyling {
    background-color: $lightGrey;
    border-radius: $buttonBorderRadius;
}

@mixin controlButtonHoverStyling {
    @include controlButtonStyling;
    background-color: $orange;
}